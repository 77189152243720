<template>
  <elements-expander class="my-4 w-full @container" :expander="data.expander">
    <div
      id="card-columns-group"
      class="grid h-max items-stretch justify-center gap-2 px-2"
      :style="{
        gridTemplateColumns: getColumns(data.cards)
      }"
    >
      <component
        :is="card.variant === 'primary' ? CardPrimary : CardSecondary"
        v-for="card of data.cards"
        :key="card.id"
        :card="card"
      />
    </div>
  </elements-expander>
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'
import CardPrimary from '@autobid/ui/components/elements/card/CardPrimary.vue'
import CardSecondary from '@autobid/ui/components/elements/card/CardSecondary.vue'
import { mapTailwindWidthToPercent } from '@autobid/ui/utils/mapTailwindWidthToPercenet'
import type { Props } from '@autobid/ui/types/components/CardColumnsGroup'

defineProps<Props>()

const getColumns = (cards: CardType[]) => {
  return cards
    .reduce((acc, { width }) => {
      return [...acc, mapTailwindWidthToPercent(width.name)]
    }, [])
    .join(' ')
}
</script>

<style>
@container (max-width: 768px) {
  #card-columns-group {
    grid-template-columns: 1fr !important;
  }
}
</style>
