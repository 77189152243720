<template>
  <CardPrimary
    :card="card"
    class="!hidden"
    :class="{
      '@md:!flex': !isSidebar,
      'md:!flex': isSidebar
    }"
  />
  <Card
    class="!flex h-max @md:!hidden @md:h-full @md:min-h-[8.5rem]"
    invert
    :as="card.button?.url ? 'a' : undefined"
    :href="card.button?.url"
    v-bind="{ ...(card.button?.newTab ? { target: '_blank' } : {}) }"
    :icon-props="{ class: '!hidden' }"
    :class="{
      '@md:!hidden @md:h-full @md:min-h-[8.5rem]': !isSidebar,
      'md:!hidden md:h-full md:min-h-[8.5rem]': isSidebar
    }"
  >
    <CardHeader invert>
      <CardHeaderIcon invert />
      <CardHeaderTitle invert />
    </CardHeader>
  </Card>
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'
import CardPrimary from './CardPrimary.vue'
import CardHeader from './CardHeader.vue'
import CardHeaderIcon from './CardHeaderIcon.vue'
import CardHeaderTitle from './CardHeaderTitle.vue'
import Card from './Card.vue'

interface Props {
  card: CardType
}

const props = defineProps<Props>()

provide('card', props.card)

const isSidebar: boolean = inject('isSidebar', false)
</script>
